import React, { Suspense, createContext } from "react";
import { useState, useEffect } from "react";
import "./globals.scss";
import "./App.scss";
import { Amplify } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import { Switch, Route, useLocation, Redirect } from "wouter";
import Home from "./home/Home";
import MyQuests from "./quests/MyQuests";
import QuestDetails from "./quests/QuestDetails";
import Profile from "./profile/Profile";
import Admin from "./admin";
import Header from "./components/Header/Header";
import TestGps from "./test_gps/TestGps";
import TestWebAppPhoneAndPlantId from "./test_web_app_phone_and_plant_id/TestWebAppPhoneAndPlantId";

awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure(awsconfig);
export const UserContext = createContext("");

export function App() {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [isFeatureTester, setIsFeatureTester] = useState<boolean | null>(null);
  const [location] = useLocation();

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const userData: CognitoUser = await Auth.currentAuthenticatedUser();
      setUser(userData);
      const userSession = userData.getSignInUserSession();

      if (userSession) {
        const accessToken = userSession.getAccessToken().getJwtToken();
        localStorage.setItem("jwt", accessToken);
        fetch(`${process.env.REACT_APP_API_URL}/test-token/`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data["admin"]) {
              setIsAdmin(true);
            } else {
              setIsAdmin(false);
            }
            if (data["feature_tester"]) {
              setIsFeatureTester(true);
            } else {
              setIsFeatureTester(false);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        localStorage.removeItem("jwt");
        console.log("No sign-in session");
      }
    } catch {
      setUser(null);
    }
  }

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (user) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [isLoggedIn, user]);

  return (
    <>
      <div className="app-container">
        {location.includes("/admin_dashboard") ? (
          isAdmin === null ? (
            <div>Loading...</div>
          ) : isAdmin ? (
            <>
              <Header
                isLoggedIn={isLoggedIn}
                setUser={setUser}
                variant="admin"
                user={user}
              />
              <Admin />
            </>
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <>
            <Header
              isLoggedIn={isLoggedIn}
              setUser={setUser}
              variant="user"
              user={user}
            />
            <Suspense fallback={<div>Loading...</div>}>
              <main>
                <UserContext.Provider value={user?.getUsername() || ""}>
                  <Switch>
                    <Route path="/">{(params) => <Home />}</Route>
                    <Route path="/quests" component={Profile} />
                    <Route path="/quests/:id">
                      {(params) => <QuestDetails />}
                    </Route>
                    <Route path="/my_quests" component={MyQuests} />
                    {/* <Route path="/profile" component={Profile} /> */}
                    <Route path="/profile">{(params) => <Profile />}</Route>
                    <Route path="/test_gps">
                      <TestGps isFeatureTester={isFeatureTester} />
                    </Route>

                    <Route path="/test_web_app_phone_and_plant_id">
                      <TestWebAppPhoneAndPlantId
                        isFeatureTester={isFeatureTester}
                      />
                    </Route>
                    {/* Default route in a switch */}
                    <Route>404: No such page!</Route>
                  </Switch>
                </UserContext.Provider>
              </main>
            </Suspense>
            <footer>
              <div>&copy; 2024 Nobos Adventure</div>
            </footer>
          </>
        )}
      </div>
    </>
  );
}

export default App;
