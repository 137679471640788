import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { postFetcher, putFetcher, fetcher, universalFetcher } from "../fetcher";
import {
  CreateQuestSchemaType,
  EditQuestSchemaType,
} from "../../admin/admin-components/CreateQuestForm/zod-objects";
import { quest } from "../../dto/interfaces";

export default function useQuestData(dataId: number) {
  const { data, error: getQuestsDataError } = useSWR(
    [`${process.env.REACT_APP_API_URL}/quest_data/${dataId}`, dataId],
    ([url, arg]) => {
      return universalFetcher(url, arg, "GET");
    }
  );

  return {
    data,
    getQuestsDataError,
  };
}
