import { universalFetcher } from "../fetcher";
import useSWRMutation from "swr/mutation";

export default function useQuestLocation() {
  const {
    trigger: deleteQuestLocations,
    isMutating: deleteQuestLocationsIsMutating,
    error: deleteQuestLocationsError,
  } = useSWRMutation(
    `${process.env.REACT_APP_API_URL}/quests/locations`,
    (url: string, prop: { arg: number[] }) =>
      universalFetcher(url, prop.arg, "DELETE")
    // { populateCache: true, revalidate: false }
  );

  return {
    deleteQuestLocations,
    deleteQuestLocationsIsMutating,
    deleteQuestLocationsError,
  };
}
