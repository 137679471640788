import useSWR from "swr";
import { useContext, Suspense } from 'react';
import styles from './profile.module.scss';
import QuestBlock from '../components/QuestBlock';
import { adventurer_quest } from "../dto/interfaces";
import { fetcher } from '../utils/fetcher';
import { UserContext } from "../App";
import { ErrorBoundary } from "react-error-boundary";

export default function MyQuests(user: any) {
  const currentUser = useContext(UserContext);

  const { data: subscribedQuests } = useSWR<adventurer_quest[]>(
    currentUser ? `${process.env.REACT_APP_API_URL}/adventurers/${currentUser}/quests` : null,
    fetcher,
    { suspense: true }
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles['main-content']}>
          <h2>My Quests</h2>
          <div className={styles['active-quests']}>
            <ErrorBoundary fallback={<p>There was an error while attempting to fetch your quests.</p>}>
              <Suspense fallback={<div>Loading quests...</div>}>
                {subscribedQuests &&
                  subscribedQuests.map(
                    (subscribedQuest: adventurer_quest) =>
                      subscribedQuest.quest && subscribedQuest.status !== 'complete' && (
                        <QuestBlock
                          questData={subscribedQuest.quest}
                          key={Math.random()}
                        />
                      )
                  )}
              </Suspense>
            </ErrorBoundary>
          </div>
          <h2>Completed Quests</h2>
          <div className={styles['completed-quests']}>
            <ErrorBoundary fallback={<p>There was an error while attempting to fetch your quests.</p>}>
              <Suspense fallback={<div>Loading quests...</div>}>
                {subscribedQuests &&
                  subscribedQuests.map(
                    (subscribedQuest: adventurer_quest) =>
                      subscribedQuest.quest && subscribedQuest.status === 'complete' && (
                        <QuestBlock
                          questData={subscribedQuest.quest}
                          key={Math.random()}
                        />
                      )
                  )}
              </Suspense>
            </ErrorBoundary>
          </div>
        </div>
        <div className={styles.sidebar}>
          <img src="/assets/images/tree-pic1.jpg" className={styles['profile-picture']} alt="Profile" />
          <h2>Name</h2>
          <p>User details</p>
        </div>
      </div>
    </>
  )
}
