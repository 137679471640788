import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import usePlantId from "../utils/hooks/use_plantId";
import { Redirect } from "wouter";
import styles from "./testWebAppPhoneAndPlantId.module.scss";

export default function TestWebAppPhoneAndPlantId({
  isFeatureTester,
}: {
  isFeatureTester: boolean | null;
}) {
  const { getValues, register, handleSubmit, watch, setValue } = useForm<{
    image: FileList | null;
  }>({
    defaultValues: { image: null },
  });

  const {
    getPlantIdWithImages,
    getPlantIdWithImagesData,
    getPlantIdWithImagesIsMutating,
    getPlantIdWithImagesError,
  } = usePlantId();

  const [
    getPlantIdWithImagesErrorWorkaround,
    setGetPlantIdWithImagesErrorWorkaround,
  ] = useState<any>(null);

  const watchImage = watch("image", null);
  const [imagePreview, setImagePreview] = useState<File | null>(null);
  const clearImage = () => {
    setImagePreview(null);
    setValue("image", null);
  };

  const onSubmit = async () => {
    const fileList = getValues().image;
    if (!fileList) {
      return;
    }
    const image = getFirstImageOfImageList(fileList);
    if (!image) {
      return;
    }

    getPlantIdWithImages({ image }).catch((error) => {
      // workaround for error handling in useSWRMutation
      // should be populated from the useSWRMutation error
      // but isn't happening for some reason
      setGetPlantIdWithImagesErrorWorkaround(error);
    });
  };

  const getFirstImageOfImageList = useCallback((imageList: FileList) => {
    if (!imageList.length) {
      return null;
    }
    return imageList.item(0);
  }, []);

  const handleFileSelect = useCallback(
    (fileList: FileList) => {
      setImagePreview(getFirstImageOfImageList(fileList));
    },
    [setImagePreview, getFirstImageOfImageList]
  );

  useEffect(() => {
    const image = getValues().image;
    if (image !== null) {
      handleFileSelect(image);
    }
  }, [watchImage, getValues, handleFileSelect]);

  return (
    <>
      {isFeatureTester === null ? (
        <p>Loading...</p>
      ) : isFeatureTester === false ? (
        <Redirect to="/" />
      ) : (
        <div className={styles.container}>
          <h1>Test Web App Phone and Plant ID</h1>
          <p style={{ maxWidth: "50%" }}>
            This is built on top of the{" "}
            <a href="https://my.plantnet.org/" rel="noreferrer" target="_blank">
              plantnet.org api
            </a>
            . Its a minimal mvp allowing only one picture upload and using the
            "auto" setting for the "organs" parameter. The api will actually
            allow up to 5 images per identification and each image can be tagged
            with the organ it represents ("leaf", "flower", "fruit", "bark",
            "auto"). The api allows 500 free identifications per day, listed
            below with results.
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div>
              <h3>Take a picture using back facing camera:</h3>
              <p style={{ fontSize: ".8rem" }}>
                Note: this will bring up a file browser on desktop. Choose .jpg
                or .png images only
              </p>
              <label
                htmlFor="image"
                className="button"
                style={{ cursor: "pointer", color: "white", marginLeft: "1em" }}
              >
                Capture Image
              </label>
              <input
                type="file"
                id="image"
                {...register("image")}
                accept="image/*"
                capture="environment"
                style={{ display: "none" }}
              />
            </div>
            {imagePreview && (
              <img
                src={URL.createObjectURL(imagePreview)}
                alt="plant"
                style={{ width: "200px" }}
              />
            )}
            {getValues().image && (
              <>
                <button
                  type="submit"
                  className="button"
                  style={{ color: "white", width: "10em" }}
                  disabled={getPlantIdWithImagesIsMutating}
                >
                  {getPlantIdWithImagesIsMutating
                    ? "submitting"
                    : "submit this image"}
                </button>
                <button
                  onClick={clearImage}
                  style={{ cursor: "pointer", width: "10em" }}
                >
                  clear image
                </button>
                {getPlantIdWithImagesError ? (
                  <p>
                    error:{" "}
                    {JSON.stringify(
                      getPlantIdWithImagesErrorWorkaround.message
                    )}
                  </p>
                ) : (
                  getPlantIdWithImagesData && (
                    <div className={styles.resultsContainer}>
                      <p className={styles.remainingIds}>
                        <span className={styles.bold}>
                          Remaining ID Request For Today:{" "}
                        </span>
                        {
                          getPlantIdWithImagesData.remainingIdentificationRequests
                        }
                      </p>
                      <h2>Plant ID Results:</h2>
                      <div className={styles.header}>
                        <span className={styles.bold}>Best Match: </span>
                        {
                          getPlantIdWithImagesData.results[0].species
                            .scientificName
                        }
                      </div>
                      <div className={styles.header}>
                        <span className={styles.bold}>
                          Best Match Common Name(s):{" "}
                        </span>
                        {getPlantIdWithImagesData.results[0].species.commonNames.join(
                          ", "
                        )}
                      </div>
                      <div className={styles.header}>
                        <span className={styles.bold}>Best Match Score: </span>
                        {getPlantIdWithImagesData.results[0].score}
                      </div>

                      <h3 style={{ marginTop: "25px" }}>All Results:</h3>
                      {getPlantIdWithImagesData.results.map((result, index) => (
                        <div key={index} className={styles.idResult}>
                          <h4>Result {index + 1}</h4>
                          <p className={styles.bold}>Score: {result.score}</p>
                          <p>
                            <span className={styles.bold}>Common Names: </span>
                            {result.species.commonNames.join(", ")}
                          </p>
                          <p>
                            <span className={styles.bold}>
                              Scientific Name:{" "}
                            </span>
                            {result.species.scientificName}
                          </p>
                        </div>
                      ))}
                    </div>
                  )
                )}
              </>
            )}
          </form>
        </div>
      )}
    </>
  );
}
