import useSWRMutation from "swr/mutation";

export type GetPlantIdSchemaType = {
  image: File;
};

export interface PlantIdResponse {
  bestMatch: string;
  results: {
    score: number;
    species: {
      commonNames: string[];
      scientificName: string;
    };
  }[];
  remainingIdentificationRequests: number;
}

export default function usePlantId() {
  const {
    trigger: getPlantIdWithImages,
    data: getPlantIdWithImagesData,
    isMutating: getPlantIdWithImagesIsMutating,
    error: getPlantIdWithImagesError,
    reset: resetGetPlantId,
  } = useSWRMutation(
    `${process.env.REACT_APP_API_URL}/plant_id/`,
    async (key: string, arg: { arg: GetPlantIdSchemaType }) => {
      const formData = new FormData();
      formData.append("image", arg.arg.image);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/plant_id/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            enctype: "multipart/form-data",
          },
          body: formData,
        }
      );

      if (!response?.ok) {
        throw new Error("An error occurred while fetching the data.");
      }

      const data = await response.json();
      if (data.error) {
        throw new Error(data.message);
      }
      return data as PlantIdResponse;
    }
  );

  return {
    getPlantIdWithImages,
    getPlantIdWithImagesData,
    getPlantIdWithImagesIsMutating,
    getPlantIdWithImagesError,
    resetGetPlantId,
  };
}
