import useQuestData from "../../../utils/hooks/use_quest_data";
import styles from "./sampleDetail.module.scss";
import { location } from "../../../dto/interfaces";
import { PlantnetIdentification } from "./sample-types";

export default function SampleDetail({ sampleId }: { sampleId: number }) {
  const { data: dataItem } = useQuestData(sampleId);

  return (
    <>
      {dataItem && (
        <div className={styles.sampleDetailContainer}>
          <h3 className={styles.sectionTitle}>
            Sample Overview - ID {dataItem.id}
          </h3>
          <div className={styles.sampleDetailBody}>
            <div className={styles.sampleMetaData}>
              <h3>Base Data</h3>
              <div className={styles.sampleMetaDataItem}>
                <p className={styles.title}>Quest</p>
                <p>{dataItem.quest.quest_name}</p>
              </div>
              <div className={styles.sampleMetaDataItem}>
                <p className={styles.title}>Adventurer</p>
                <p>
                  {dataItem.adventurer_quest.adventurer.bio ||
                    dataItem.adventurer_quest.adventurer.id}
                </p>
              </div>
              <div className={styles.sampleMetaDataItem}>
                <p className={styles.title}>
                  Location ("locality", "administrative_area")
                </p>

                {dataItem.quest.locations.map((location: location) => {
                  return (
                    <div key={location.id} className={styles.locationItem}>
                      <p>{`${location.locality}, ${location.administrative_area}`}</p>
                    </div>
                  );
                })}
              </div>
              <div className={styles.sampleMetaDataItem}>
                <p className={styles.title}>Requirements From Quest</p>
                <p>TBD - Example requirements provided here for reference</p>
              </div>
              <div className={styles.sampleMetaDataItem}>
                <p className={styles.title}>Payment Per Sample</p>
                <p>TBD</p>
              </div>
              <div className={styles.sampleMetaDataItem}>
                <p className={styles.title}>
                  Data Item GPS Coordinates (device)
                </p>
                <p>{dataItem.coordinates}</p>
                {dataItem.coordinates && (
                  <img
                    alt="Google Map of coordinates"
                    src={`https://maps.googleapis.com/maps/api/staticmap?markers=${
                      dataItem.coordinates.split(",")[0]
                    },${
                      dataItem.coordinates.split(",")[1]
                    }&size=400x400&key=AIzaSyCKQAAr5XtTgPj56D-SQXfP8m-MmlOMZk0`}
                    style={{ width: "100%" }}
                  />
                )}
              </div>
            </div>

            <div className={styles.verticalDivider} />

            <div className={styles.sampleData}>
              {dataItem.image_data_presigned_url && (
                <img
                  src={dataItem.image_data_presigned_url}
                  alt="Sample"
                  className={styles.sampleImage}
                />
              )}

              <div className={styles.actionsContainer}>
                <div>Accept (tbd)</div>
                <div>Deny (tbd)</div>
                <div>Request Change (tbd)</div>
              </div>

              <div className={styles.sampleDataItem}>
                <h3>Additional Data</h3>
                {dataItem.additional_data &&
                  Object.entries(dataItem.additional_data).map(
                    ([key, value], i) => {
                      // Skip plant_id_data as it is handled separately below
                      if (key === "plant_id_data") {
                        return;
                      }
                      return (
                        <div key={i} className={styles.additionalDataItem}>
                          <p className={styles.title}>{key}</p>
                          <p>{value as string | number}</p>
                        </div>
                      );
                    }
                  )}
                {dataItem.additional_data?.plant_id_data && (
                  <div>
                    <h4 style={{ margin: "0" }}>Plant ID Data (Plantnet)</h4>

                    {Object.entries(dataItem.additional_data.plant_id_data).map(
                      ([key, value]) => {
                        return (
                          key === "plantnet" &&
                          Object.entries(
                            value as { [key: string]: PlantnetIdentification }
                          ).map(([key, value], i) => {
                            if (value.error) {
                              return (
                                <div key={i} style={{ marginBottom: "1rem" }}>
                                  <p className={styles.title}>Error:</p>
                                  <p>{value.error}</p>
                                </div>
                              );
                            }
                            return (
                              <div key={i}>
                                <div style={{ marginBottom: "1rem" }}>
                                  <p className={styles.title}>Best Match:</p>
                                  <p>{value.bestMatch}</p>
                                </div>
                                <div className={styles.plantIdResultsContainer}>
                                  {value.results
                                    .slice(
                                      0,
                                      value.results.length > 3
                                        ? 3
                                        : value.results.length
                                    )
                                    .map((result, j) => {
                                      return (
                                        <div
                                          key={j}
                                          className={
                                            styles.plantIdResultContainer
                                          }
                                        >
                                          <p
                                            className={`${styles.title} ${styles.underline}`}
                                          >
                                            Result {j + 1}:
                                          </p>

                                          <div className={styles.plantIdResult}>
                                            <p className={styles.title}>
                                              Score:
                                            </p>
                                            <p>{result.score}</p>
                                          </div>

                                          <div className={styles.plantIdResult}>
                                            <p className={styles.title}>
                                              Common Names:
                                            </p>
                                            <p>
                                              {result.species.commonNames.join(
                                                ", "
                                              )}
                                            </p>
                                          </div>

                                          <div className={styles.plantIdResult}>
                                            <p className={styles.title}>
                                              Scientific Name:
                                            </p>
                                            <p>
                                              {result.species.scientificName}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            );
                          })
                        );
                      }
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
