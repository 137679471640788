"use client";
import { useContext, Suspense, useRef, useState } from "react";
import useSWR from "swr";
import { useParams } from "wouter";
import Markdown from "react-markdown";
import styles from "./quest_details.module.scss";
import { quest, adventurer_quest } from "../dto/interfaces";
import Button from "../components/Button/Button";
import Modal from "../components/Modal/Modal";
import { UserContext } from "../App";
import { fetcher } from "../utils/fetcher";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import {
  createTreeMeasurementQuestDataItemSchema,
  CreateTreeMeasurementQuestDataItemSchemaType,
} from "../admin/admin-components/CreateQuestForm/zod-objects";
import { zodResolver } from "@hookform/resolvers/zod";
import { getGeoCoordinates } from "../utils/geolocation";

const QuestDetails = () => {
  const params = useParams();
  const adventurer_id = useContext(UserContext);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const BeingQuestModalRef = useRef<any>();
  const [isSignUpSuccessful, setSignUpSuccessful] = useState(false);
  const [submittingDataItem, setSubmittingDataItem] = useState(false);

  const closeQuestModal = () => {
    BeingQuestModalRef?.current?.setVisible(false);
  };

  const { data: adventurerQuest } = useSWR<adventurer_quest>(
    adventurer_id
      ? `${process.env.REACT_APP_API_URL}/adventurers/${adventurer_id}/quests/${params.id}`
      : null,
    fetcher,
    { suspense: true }
  );

  const {
    data: quest,
    error,
    isLoading,
  } = useSWR<quest>(
    `${process.env.REACT_APP_API_URL}/quests/${params.id}`,
    fetcher,
    { suspense: true }
  );

  const { data: uploadedFiles, mutate: refreshFiles } = useSWR(
    adventurerQuest?.quest
      ? `${process.env.REACT_APP_API_URL}/adventurers/${adventurer_id}/quests/${params.id}/data`
      : null,
    fetcher
  );

  const beginQuest = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/adventurers/${adventurer_id}/quests/${params.id}/sign-up`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      if (response.ok) {
        setSignUpSuccessful(true);
        closeQuestModal();
      } else {
        console.error("Failed to sign up for the quest");
      }
    } catch (error) {
      console.error("Error signing up for the quest:", error);
    }
  };

  // init form

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateTreeMeasurementQuestDataItemSchemaType>({
    defaultValues: {
      image: undefined,
      additional_data: {
        tree_width: undefined,
        tree_height: undefined,
        user_supplied_latitude: null,
        user_supplied_longitude: null,
      },
    },
    resolver: zodResolver(createTreeMeasurementQuestDataItemSchema),
  });

  const onSubmit = async (
    data: CreateTreeMeasurementQuestDataItemSchemaType
  ) => {
    setSubmittingDataItem(true);

    const file = data.image[0];
    // @TODO: check for type of file?
    // <input> only accepts images though, so is redundant
    // this undefined check is also redundant since file is required by resolver
    if (!file) {
      return;
    }

    const geoCoordinates = await getGeoCoordinates().catch((error) => {
      // @TODO: do we want to still submit with no device gps? Probably yes?
      console.error("Error getting geo coordinates:", error);
    });

    const formData = new FormData();

    // ADD VALUES TO FORMDATA OBJECT
    // needed to send image AND data
    //  @TODO CLEAN UP!!
    // (but also make variable per quest type, so plan for that)
    data.additional_data.tree_height &&
      formData.append(
        "tree_height",
        data.additional_data.tree_height.toString()
      );
    data.additional_data.tree_width &&
      formData.append("tree_width", data.additional_data.tree_width.toString());
    geoCoordinates?.latitude &&
      formData.append("latitude", geoCoordinates.latitude.toString());
    geoCoordinates?.longitude &&
      formData.append("longitude", geoCoordinates.longitude.toString());
    data.additional_data.user_supplied_latitude &&
      formData.append(
        "user_supplied_latitude",
        data.additional_data.user_supplied_latitude.toString()
      );
    data.additional_data.user_supplied_longitude &&
      formData.append(
        "user_supplied_longitude",
        data.additional_data.user_supplied_longitude.toString()
      );

    file && formData.append("file", file);
    // END ADDING VALUES TO FORM DATA OBJECT

    // @TODO convert this into swr hook
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/adventurers/${adventurer_id}/quests/${params.id}/data/uploads?data_type=image`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        refreshFiles(); // Refresh the list of uploaded files
      } else {
        console.error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    reset();
    setSubmittingDataItem(false);
  };

  async function handleSignIn() {
    try {
      await Auth.federatedSignIn();
    } catch (error) {
      console.log("Error signing in", error);
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading quest details</div>;
  }

  if (!quest) {
    return <div>No quest found</div>;
  }

  const deadline = quest.quest_deadline
    ? new Date(quest.quest_deadline).toLocaleDateString()
    : "None";

  return (
    <>
      <div className={styles.quest_image_hero}>
        <img src="/assets/images/quest-details-hero.jpg" alt="" />
      </div>

      <div className={styles.container}>
        <div className={styles.content}>
          <Suspense fallback={<div>Loading quest...</div>}>
            <h1 style={{ margin: 0 }}>{quest.quest_name}</h1>
            <dl className={styles.quest_details}>
              <dt>Location:</dt>
              <dd>
                {quest.locations &&
                  `${quest.locations[0].locality}, ${quest.locations[0].administrative_area}`}
              </dd>
              <dt>Deadline:</dt>
              <dd>{deadline}</dd>
            </dl>

            <Markdown>{quest.quest_description}</Markdown>

            {isSignUpSuccessful && (
              <div className={styles.signup_success}>
                You have successfully signed up for the quest:{" "}
                <strong>{quest.quest_name}!</strong>
              </div>
            )}
          </Suspense>
        </div>

        {adventurerQuest?.quest || isSignUpSuccessful ? (
          <div className={styles.upload}>
            <h2>Upload Data Item</h2>

            <p>
              You are currently engaged in this quest! Please upload your data
              below.
            </p>
            <div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div className={styles.formItem}>
                  <label htmlFor="additional_data.tree_width">
                    Tree Width (in cm)
                  </label>

                  <input
                    type="number"
                    step="0.01"
                    {...register("additional_data.tree_width", {
                      setValueAs: (v) => {
                        return !!v ? parseFloat(v) : null;
                      },
                    })}
                  />
                  <p className={styles.formError}>
                    {errors.additional_data?.tree_width?.message}
                  </p>
                </div>

                <div className={styles.formItem}>
                  <label htmlFor="additional_data.tree_height">
                    Tree Height (in cm)
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    {...register("additional_data.tree_height", {
                      setValueAs: (v) => {
                        return !!v ? parseFloat(v) : null;
                      },
                    })}
                  />
                  <p className={styles.formError}>
                    {errors.additional_data?.tree_height?.message}
                  </p>
                </div>

                <div className={styles.formItem}>
                  <label htmlFor="additional_data.user_supplied_latitude">
                    External Device Latitude
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    {...register("additional_data.user_supplied_latitude", {
                      setValueAs: (v) => {
                        return !!v ? parseFloat(v) : undefined;
                      },
                    })}
                  />
                  <p className={styles.formError}>
                    {errors.additional_data?.user_supplied_latitude?.message}
                  </p>
                </div>

                <div className={styles.formItem}>
                  <label htmlFor="additional_data.user_supplied_longitude">
                    External Device Longitude
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    {...register("additional_data.user_supplied_longitude", {
                      setValueAs: (v) => {
                        return !!v ? parseFloat(v) : undefined;
                      },
                    })}
                  />
                  <p className={styles.formError}>
                    {errors.additional_data?.user_supplied_longitude?.message}
                  </p>
                </div>

                <input
                  type="file"
                  id="image"
                  {...register("image")}
                  accept="image/*"
                  // capture="environment"
                  // style={{ display: "none" }}
                />

                <p className={styles.formError}>{errors.image?.message}</p>

                <div>
                  <input type="submit" disabled={submittingDataItem} />
                </div>
              </form>

              {Array.isArray(uploadedFiles) && (
                <div>
                  <h3>Uploaded Data Items</h3>
                  <ul>
                    {uploadedFiles.map((file: any) => {
                      // Extract the file name from the data_url
                      const fileName = file.data_url?.split("/").pop();
                      return (
                        <>
                          <li key={file.id}>
                            <p>Filename: {fileName}</p>
                            <img
                              src={file.image_data_presigned_url}
                              alt="data image"
                              style={{ width: "200px" }}
                            />
                            {file.additional_data &&
                              Object.entries(file.additional_data).map(
                                ([key, value]) => {
                                  if (key === "plant_id_data") {
                                    return;
                                  }

                                  return (
                                    <div
                                      key={key}
                                      className={styles.additionalDataItem}
                                    >
                                      <p>
                                        <span>{key}</span>:{" "}
                                        <span className={styles.bold}>
                                          {value as string | number}
                                        </span>
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                          </li>
                          <hr
                            style={{
                              width: "90%",
                              height: "1px",
                              backgroundColor: "grey",
                              marginBottom: "20px",
                            }}
                          />
                        </>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ) : adventurer_id && !adventurerQuest?.quest ? (
          <Modal
            trigger={<Button label="Begin quest" />}
            title="Sign up for this quest"
            _ref={BeingQuestModalRef}
          >
            <>
              <p>
                Please confirm you meet all the location and gear requirements
                for the quest: <strong>{quest.quest_name}</strong>.
              </p>
              <Button label="Yes, sign me up!" callback={beginQuest} />
              &nbsp;&nbsp;&nbsp;
              <Button label="Actually, no..." callback={closeQuestModal} />
            </>
          </Modal>
        ) : (
          <div>
            <span
              onClick={handleSignIn}
              style={{
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
              }}
            >
              Sign up
            </span>{" "}
            to go on an adventure!
          </div>
        )}
      </div>
    </>
  );
};

export default QuestDetails;
