import useSWR from "swr";
import { organization } from "../../dto/interfaces";
import { fetcher } from "../fetcher";

export default function useOrganization() {
  const {
    data: organizations,
    isLoading: isLoadingOrganizations,
    error: getOrganizationsError,
  } = useSWR<organization[]>(
    `${process.env.REACT_APP_API_URL}/organizations/`,
    fetcher
  );

  return {
    organizations,
    isLoadingOrganizations,
    getOrganizationsError,
  };
}
