import { feature_testing_test_gps_bad_data } from "../../dto/interfaces";
import useTestFeatureTestGps from "../../utils/hooks/use_test_gps";
import styles from "./featureTesting.module.scss";

export default function FeatureTesting() {
  const { getTestFeatureTestGpsData } = useTestFeatureTestGps();
  return (
    <div className={styles.container}>
      <div className={styles.items}>
        <div className={styles.titleContainer}>
          <h1>Bad GPS Identifications</h1>
        </div>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th scope="col">Coordinates</th>
                <th scope="col">Location</th>
                <th scope="col">Details</th>
                <th scope="col">Solved By High Accuracy Setting</th>
                <th scope="col">Location Same on Native App</th>
                <th scope="col">Image url</th>
              </tr>
            </thead>
            {Array.isArray(getTestFeatureTestGpsData) && (
              <tbody>
                {getTestFeatureTestGpsData.map(
                  (
                    getTestFeatureTestGpsDataItem: feature_testing_test_gps_bad_data
                  ) => (
                    <tr key={getTestFeatureTestGpsDataItem.id}>
                      <td>{`${getTestFeatureTestGpsDataItem.coordinates[0]}, ${getTestFeatureTestGpsDataItem.coordinates[1]}`}</td>
                      <td>{getTestFeatureTestGpsDataItem.location}</td>
                      <td>{getTestFeatureTestGpsDataItem.details}</td>
                      <td>
                        {getTestFeatureTestGpsDataItem.highAccuracySolved.toString()}
                      </td>
                      <td>
                        {getTestFeatureTestGpsDataItem.sameAsNative.toString()}
                      </td>
                      <td>
                        <a
                          href={`https://maps.googleapis.com/maps/api/staticmap?markers=${getTestFeatureTestGpsDataItem.coordinates[0]},${getTestFeatureTestGpsDataItem.coordinates[1]}&size=400x400&key=AIzaSyCKQAAr5XtTgPj56D-SQXfP8m-MmlOMZk0`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {`https://maps.googleapis.com/maps/api/staticmap?markers=${getTestFeatureTestGpsDataItem.coordinates[0]},${getTestFeatureTestGpsDataItem.coordinates[1]}&size=400x400&key=AIzaSyCKQAAr5XtTgPj56D-SQXfP8m-MmlOMZk0`}
                        </a>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}
