import { Route, Router, Switch, useLocation } from "wouter";
import AdminHome from "./admin-home/AdminHome";
import Applications from "./applications/Applications";
import Datasets from "./datasets/Datasets";
import Samples from "./samples/Samples";
import Settings from "./settings/Settings";
import FeatureTesting from "./feature-testing/FeatureTesting";
import { PropsWithChildren } from "react";

export const NestedRoutes = (props: PropsWithChildren<{ base: string }>) => {
  const [location] = useLocation();

  if (!location.startsWith(props.base)) return null;

  // we need key to make sure the router will remount if the base changes
  return (
    <Router base={props.base} key={props.base}>
      {props.children}
    </Router>
  );
};

export default function AdminDashboardIndex() {
  return (
    <Switch>
      <Route path="admin_dashboard" component={AdminHome} />
      <Route path="admin_dashboard/applications" component={Applications} />
      <Route path="admin_dashboard/datasets" component={Datasets} />
      <Route path="admin_dashboard/samples/:id?">
        <Samples />
      </Route>
      <Route path="admin_dashboard/settings" component={Settings} />
      <Route
        path="admin_dashboard/feature-testing"
        component={FeatureTesting}
      />

      {/* Default route in a switch */}
      <Route>404: No such page here!</Route>
    </Switch>
  );
}
