import { useState, useRef, useEffect } from 'react';
import styles from './dropdown.module.scss';

interface DropdownProps {
    title: string | React.ReactNode;
    children: JSX.Element;
    visible?: boolean;
    orientation?: string;
}

export default function Dropdown({ title, children, visible, orientation }: DropdownProps) {
    const [isVisible, setVisible] = useState(visible || false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event: { target: any; }) {
            if (ref.current && !ref.current.contains(event.target)) {
                setVisible(false);
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }

    const handleClick = () => {
        setVisible(!isVisible);
    }

    return (
        <div className={styles.dropdown_container}>
            <div className={styles.dropdown_title} onClick={handleClick}>
                {title}
            </div>

            {isVisible && <div className={styles.dropdown_content} style={{right: orientation === 'right' ? '0' : 'auto'}} ref={wrapperRef}>
                {children}
            </div>}
        </div>
    );
}
