import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  useFieldArray,
  UseFormRegister,
} from "react-hook-form";
import type { location } from "../../../dto/interfaces";
import styles from "./create-quest-form.module.scss";
import {
  CreateOrUpdateLocationSchemaType,
  CreateQuestSchemaType,
} from "./zod-objects";

export type LocationQuestIDOptional = Omit<location, "quest_id" | "id"> & {
  quest_id?: string;
};

type LocationSectionProps = {
  register: UseFormRegister<CreateQuestSchemaType>;
  errors: FieldErrors<CreateQuestSchemaType>;
  recordDeletedLocation: (v: number) => void;
  control: Control<CreateQuestSchemaType>;
};

type LocationProps = {
  location: FieldArrayWithId<CreateQuestSchemaType>;
  register: UseFormRegister<CreateQuestSchemaType>;
  errors: FieldErrors<CreateQuestSchemaType>;
  index: number;
};

type LocationElementProps = {
  location: FieldArrayWithId<CreateQuestSchemaType>;
  // @TODO should be able to get rid of any typing
  //  hints at refactor to exmple nested form example here:
  // https://react-hook-form.com/docs/usefieldarray
  register: UseFormRegister<any>;
  errors: FieldErrors<CreateQuestSchemaType>;
  index: number;
  locationElement: keyof CreateOrUpdateLocationSchemaType;
  copy: string;
  setValueAs?: (v: any) => any;
};

export const LocationSection = ({
  register,
  errors,
  recordDeletedLocation,
  control,
}: LocationSectionProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "locations",
  });

  return (
    <>
      {fields.length >= 0 &&
        fields.map((location, i) => (
          <div className={styles.formSubsection}>
            <div style={{ display: "flex" }}>
              <h5>Location {i + 1}</h5>

              {/* DELETE THE LOCATION */}
              <div
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  recordDeletedLocation(i);
                  remove(i);
                }}
              >
                delete location
              </div>
            </div>
            <Location
              location={location}
              index={i}
              register={register}
              errors={errors}
            />
          </div>
        ))}

      {/* ADD A LOCATION */}
      <div
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={() => {
          // @TODO: this should work
          // append(getLocationDefaults());
          append({
            id: null,
            address_line1: null,
            address_line2: null,
            locality: "",
            administrative_area: "",
            postal_code: "",
            // @ts-ignore
            coordinates: [],
            country: "",
          });
        }}
      >
        add location
      </div>
    </>
  );
};

const Location = ({ location, index, register, errors }: LocationProps) => {
  return (
    <>
      <input
        type="hidden"
        {...register(`locations.${index}.id`, {
          setValueAs: (v: any) => {
            if (!v) {
              return null;
            }
            return parseInt(v);
          },
        })}
      />
      <LocationElement
        location={location}
        register={register}
        errors={errors}
        index={index}
        locationElement="address_line1"
        copy="address line 1"
      />
      <LocationElement
        location={location}
        register={register}
        errors={errors}
        index={index}
        locationElement="address_line2"
        copy="address line 2"
      />
      <LocationElement
        location={location}
        register={register}
        errors={errors}
        index={index}
        locationElement="locality"
        copy="locality (city)"
      />
      <LocationElement
        location={location}
        register={register}
        errors={errors}
        index={index}
        locationElement="administrative_area"
        copy="administrative area"
      />
      <LocationElement
        location={location}
        register={register}
        errors={errors}
        index={index}
        locationElement="postal_code"
        copy="postal code"
      />
      <LocationElement
        location={location}
        register={register}
        errors={errors}
        index={index}
        locationElement="coordinates"
        copy="coordinates"
        setValueAs={(v: string | []) => {
          // this is for the form as edit form,
          // the coordinates start as number[]
          // and need to be converted to string
          // to be used in the form field
          if (Array.isArray(v)) {
            v = v.toString();
          }
          const coords = v.split(",").reduce((acc: number[], v: string) => {
            const parsedString = parseFloat(v);
            if (!isNaN(parsedString)) {
              acc.push(parsedString);
            }
            return acc;
          }, []);
          return coords;
        }}
      />
      <LocationElement
        location={location}
        register={register}
        errors={errors}
        index={index}
        locationElement="country"
        copy="country"
      />
    </>
  );
};

const LocationElement = ({
  location,
  register,
  errors,
  locationElement,
  copy,
  setValueAs,
  index,
}: LocationElementProps) => {
  return (
    <div className={styles.formSubsectionItemWithError}>
      <div className={`${styles.formSubsectionItem}`}>
        <label htmlFor={`locations.${index}.${locationElement}`}>{copy}</label>
        <input
          key={location.id}
          {...register(`locations.${index}.${locationElement}`, {
            setValueAs: setValueAs,
          })}
        />
      </div>
      {errors.locations?.[index]?.[locationElement]?.message && (
        <p>{`${errors.locations?.[index]?.[`${locationElement}`]?.message}`}</p>
      )}
    </div>
  );
};
