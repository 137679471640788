import { CognitoUser } from "amazon-cognito-identity-js";

const isFeatureTester = (user: CognitoUser) => {
  return user
    ?.getSignInUserSession()
    ?.getIdToken()
    .payload["cognito:groups"].includes("feature_testers");
};

export interface NavLink {
  href: string;
  displayText: string;
  ariaLabel: string;
  restricted?: (user: CognitoUser | null) => boolean;
}

export const userLinks: NavLink[] = [
  {
    href: "/quests",
    displayText: "Quests",
    ariaLabel: "Go To Quests",
    restricted: (user: CognitoUser | null) => false,
  },
  {
    href: "/test_gps",
    displayText: "Feature Test: Device GPS Accuracy",
    ariaLabel: "Go To Feature Test: Device GPS Accuracy",
    restricted: (user: CognitoUser | null) => {
      if (!user) {
        return true;
      }
      return !isFeatureTester(user);
    },
  },
  {
    href: "/test_web_app_phone_and_plant_id",
    displayText: "Feature Test: Web App Phone and Plant ID Accuracy",
    ariaLabel: "Go To Feature Test: Web App Phone and Plant ID Accuracy",
    restricted: (user: CognitoUser | null) => {
      if (!user) {
        return true;
      }
      return !isFeatureTester(user);
    },
  },
];

export const siteLInks: NavLink[] = [
  {
    href: "https://nobos.earth/adventurers/",
    displayText: "Adventurers",
    ariaLabel: "Go To Adventurers",
    restricted: (user: CognitoUser | null) => false,
  },
  {
    href: "https://nobos.earth/environmentalists/",
    displayText: "Environmentalists",
    ariaLabel: "Go To Environmentalists",
    restricted: (user: CognitoUser | null) => false,
  },
  {
    href: "https://nobos.earth/about/",
    displayText: "About",
    ariaLabel: "Go To About",
    restricted: (user: CognitoUser | null) => false,
  },
];

export const adminLinks: NavLink[] = [
  {
    href: "/admin_dashboard",
    displayText: "Dashboard",
    ariaLabel: "Go To Admin Dashboard",
  },
  {
    href: "/admin_dashboard/applications",
    displayText: "Applications",
    ariaLabel: "Go To Applications",
  },
  {
    href: "/admin_dashboard/samples",
    displayText: "Samples",
    ariaLabel: "Go To Samples",
  },
  {
    href: "/admin_dashboard/datasets",
    displayText: "Datasets",
    ariaLabel: "Go To Datasets",
  },
  {
    href: "/admin_dashboard/settings",
    displayText: "Settings",
    ariaLabel: "Go To Settings",
  },
  {
    href: "/admin_dashboard/feature-testing",
    displayText: "Feature Testing",
    ariaLabel: "Go To Feature Testing",
  },
];
