import { quest, Quest_Status } from "../../../dto/interfaces";

export const getLocationDefaults = () => {
  return {
    address_line1: null,
    address_line2: null,
    locality: undefined,
    administrative_area: undefined,
    postal_code: undefined,
    coordinates: [] as [(number | undefined)?, (number | undefined)?],
    country: undefined,
  };
};

export const getDefaultValues = (quest?: quest) => {
  const editFormDefaultValues = (quest: quest) => {
    return {
      quest_name: quest.quest_name,
      quest_status: quest.quest_status as Quest_Status,
      quest_description: quest.quest_description,
      organization: {
        organization_id: quest.organization?.id,
      },
      locations: quest.locations?.map((location) => {
        return {
          id: location.id,
          address_line1: location.address_line1,
          address_line2: location.address_line2,
          locality: location.locality,
          administrative_area: location.administrative_area,
          postal_code: location.postal_code,
          coordinates: location.coordinates as [
            (number | undefined)?,
            (number | undefined)?
          ],
          country: location.country,
        };
      }) || [getLocationDefaults()],
      quest_deadline: quest.quest_deadline
        ? (new Date(quest.quest_deadline)
            .toISOString()
            .split("T")[0] as unknown as Date)
        : null,
      adventurer_limit: quest.adventurer_limit,
    };
  };

  const createFormDefaultValues = {
    quest_name: undefined,
    quest_status: "INACTIVE" as Quest_Status,
    quest_description: null,
    organization: {
      organization_id: undefined,
    },
    locations: [getLocationDefaults()],
    quest_deadline: null,
    adventurer_limit: null,
  };

  return !!quest ? editFormDefaultValues(quest) : createFormDefaultValues;
};
