import useSWR from "swr";
import { useContext, useState, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Map, ZoomControl, Marker, Overlay, Point } from "pigeon-maps";
import { Link } from "wouter";
import { Auth } from "aws-amplify";
import { quest, adventurer_quest } from "../dto/interfaces";
import { fetcher } from "../utils/fetcher";
import { UserContext } from "../App";
import QuestBlock from "../components/QuestBlock";
import styles from "./home.module.scss";

export default function Home() {
  const currentUser = useContext(UserContext);

  const { data: availableQuests } = useSWR<quest[]>(
    `${process.env.REACT_APP_API_URL}/quests`,
    fetcher,
    { suspense: true }
  );

  const { data: subscribedQuests } = useSWR<adventurer_quest[]>(
    currentUser
      ? `${process.env.REACT_APP_API_URL}/adventurers/${currentUser}/quests`
      : null,
    fetcher,
    { suspense: true }
  );

  const initialAnchor: Point = [0, 0];
  const initialQuest = {
    id: 0,
    quest_name: "",
    quest_image: "",
    organization_id: 0,
    location_id: 0,
    quest_description: "",
    quest_deadline: "",
    adventurer_limit: "",
  };
  const [center, setCenter] = useState<[number, number]>([40, -105]);
  const [zoom, setZoom] = useState(4);
  const [overlay, setOverlay] = useState({
    anchor: initialAnchor,
    quest: initialQuest,
  });
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const handleMarker = (e: { event?: Event; anchor?: Point }, quest: any) => {
    setOverlay({
      anchor: e.anchor || initialAnchor,
      quest: quest,
    });
    setIsOverlayVisible(!isOverlayVisible);
  };

  async function handleSignIn() {
    try {
      await Auth.federatedSignIn();
    } catch (error) {
      console.log("Error signing in", error);
    }
  }

  return (
    <>
      <h1 className={styles.title}>Welcome {currentUser ? `back, adventurer` : 'to Nobos'}</h1>

      {!currentUser && (
        <p>
          Want to go on adventure? Please{" "}
          <span
            onClick={handleSignIn}
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
          >
            sign up
          </span>{" "}
          here!
        </p>
      )}

      {currentUser && (
        <>
          <h2 className={styles.sectionTitle}>Continue Your Quests</h2>

          <ErrorBoundary
            fallback={
              <p>There was an error while attempting to fetch your quests.</p>
            }
          >
            <Suspense fallback={<div>Loading quests...</div>}>
              <div className={styles.my_quests}>
                {subscribedQuests &&
                  subscribedQuests.map(
                    (subscribedQuest: adventurer_quest) =>
                      subscribedQuest.quest && (
                        <QuestBlock
                          questData={subscribedQuest.quest}
                          key={Math.random()}
                        />
                      )
                  )}
              </div>
            </Suspense>
          </ErrorBoundary>
        </>
      )}

      <h2>Available Quests</h2>

      <div className={styles.container}>
        <div className={styles.map}>
          <Map
            height={500}
            center={center}
            zoom={zoom}
            onBoundsChanged={({ center, zoom }) => {
              setCenter(center);
              setZoom(zoom);
            }}
          >
            <ZoomControl />
            {availableQuests &&
              availableQuests.map((quest: quest) => (
                <Marker
                  width={50}
                  anchor={[
                    quest.locations ? quest.locations[0].coordinates[0] : 0,
                    quest.locations ? quest.locations[0].coordinates[1] : 0,
                  ]}
                  key={Math.random()}
                  onClick={(e) => handleMarker(e, quest)}
                />
              ))}

            {isOverlayVisible && (
              <Overlay anchor={overlay.anchor} offset={[35, 0]}>
                <div className={styles.marker_container}>
                  <h1 className={styles.marker_container_heading}>
                    {overlay.quest && overlay.quest.quest_name}
                  </h1>
                  <p>{overlay.quest && overlay.quest.quest_description}</p>
                  <p>
                    deadline:{" "}
                    {overlay.quest &&
                      new Date(
                        overlay.quest.quest_deadline
                      ).toLocaleDateString()}
                  </p>

                  <Link href={`/quests/${overlay.quest.id}`}>more details</Link>
                </div>
              </Overlay>
            )}
          </Map>
        </div>

        <ErrorBoundary
          fallback={
            <p>
              There was an error while attempting to fetch available quests.
            </p>
          }
        >
          <Suspense fallback={<div>Loading quests...</div>}>
            <div className={styles.quests}>
              {availableQuests &&
                availableQuests.map((quest: quest) => (
                  <QuestBlock
                    questData={quest}
                    orientation="vertical"
                    key={Math.random()}
                  />
                ))}
            </div>
          </Suspense>
        </ErrorBoundary>
      </div>
    </>
  );
}
