import {
  questSchema as generatedQuestSchema,
  locationSchema as generatedLocationSchema,
} from "../../../dto/zod";
import * as z from "zod";

const createOrUpdateLocationSchema = generatedLocationSchema
  .omit({
    id: true,
    quest_id: true,
  })
  .extend({
    id: z.number().int().nullable().optional(),
    locality: z.string().min(2).max(120),
    administrative_area: z.string().min(2).max(120),
    postal_code: z.string().min(3).max(30),
    coordinates: z.tuple([z.number(), z.number()]),
    country: z.string().min(2).max(120),
  });

export type CreateOrUpdateLocationSchemaType = z.infer<
  typeof createOrUpdateLocationSchema
>;

const createOrganizationSchema = z.object({
  organization_id: z.union([z.number().int(), z.undefined()]),
});

export const createQuestSchema = generatedQuestSchema
  .omit({ id: true, quest_image: true, organization_id: true })
  .extend({
    quest_name: z.string().min(3).max(120),
    locations: z.array(createOrUpdateLocationSchema),
    organization: createOrganizationSchema,
    deleted_locations: z.array(z.number().int()).optional(),
  });

export type CreateQuestSchemaType = z.infer<typeof createQuestSchema>;

export type EditQuestSchemaType = {
  quest_id: number;
  quest_data: CreateQuestSchemaType;
};

// Noting this is possible to more finely validate the actual file
// being uploaded, from here in zod by integrating this into the schema.
// Currently, types are handled by html input and file size is not handled
// on the front end. There is a server side check for file size.
// const MAX_FILE_SIZE = 5000000;
// const ACCEPTED_IMAGE_TYPES = [
//   "image/jpeg",
//   "image/jpg",
//   "image/png",
//   "image/webp",
// ];

export const createTreeMeasurementQuestDataItemSchema = z.object({
  image: z
    .instanceof(FileList)
    .refine((value) => value.length > 0, { message: "image upload required" }),
  additional_data: z.object({
    tree_width: z
      .number({ invalid_type_error: "tree width required" })
      .positive()
      .min(1),
    tree_height: z
      .number({ invalid_type_error: "tree height required" })
      .positive()
      .min(1),
    user_supplied_latitude: z.number().nullable().optional(),
    user_supplied_longitude: z.number().nullable().optional(),
  }),
});

export type CreateTreeMeasurementQuestDataItemSchemaType = z.infer<
  typeof createTreeMeasurementQuestDataItemSchema
>;
