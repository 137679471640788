const awsconfig = {
  aws_project_region: "us-east-2",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_4wLbYo86r",
  aws_user_pools_web_client_id: "1t0e6kr2r6nhvmalkh9lf4t049",
  oauth: {
    domain: "nobos.auth.us-east-2.amazoncognito.com",
    scope: ["email", "profile", "openid"],
    redirectSignIn: "https://app.nobos.earth/,http://localhost:3000/",
    redirectSignOut: "https://app.nobos.earth/,http://localhost:3000/",
    responseType: "code", 
  },
};

export default awsconfig;
