import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./header.module.scss";
import { Link } from "wouter";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth, Hub } from "aws-amplify";
import { userLinks, siteLInks, adminLinks, NavLink } from "./header-link-data";
import { useState, useEffect } from "react";

interface VariantProps {
  backgroundClass: string;
  links: NavLink[];
  logoUrl: string;
  logoLink: string;
  navItemStyle: string;
}

const userVariant: VariantProps = {
  backgroundClass: "",
  links: userLinks,
  logoUrl: "/assets/nobos-logo-white.svg",
  logoLink: "/",
  navItemStyle: styles["user-nav-item"],
};

const adminVariant: VariantProps = {
  backgroundClass: "",
  links: adminLinks,
  logoUrl: "/assets/nobos-logo-white.svg",
  logoLink: "/",
  navItemStyle: styles["admin-nav-item"],
};

interface HeaderProps {
  isLoggedIn: boolean;
  variant: "user" | "admin";
  setUser: React.Dispatch<React.SetStateAction<CognitoUser | null>>;
  user: CognitoUser | null;
}

export default function Header({
  isLoggedIn,
  variant,
  setUser,
  user,
}: HeaderProps) {
  const isAdmin = user
    ?.getSignInUserSession()
    ?.getIdToken()
    .payload["cognito:groups"].includes("admins");

  const getActiveVariantProps = (variant: "user" | "admin") => {
    switch (variant) {
      case "user":
        return userVariant;
      case "admin":
        return adminVariant;
      default:
        return userVariant;
    }
  };
  const [activeVariantProps, setActiveVariantProps] = useState(() => {
    return getActiveVariantProps(variant);
  });

  useEffect(() => {
    const listener = (data: any) => {
      switch (data.payload.event) {
        case "signIn":
          fetchUserData();
          break;
        default:
          break;
      }
    };

    Hub.listen("auth", listener);

    return () => {
      Hub.remove("auth", listener);
    };
  }, []);

  async function fetchUserData() {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const userId = currentUser.getUsername();
      const userSession = currentUser.getSignInUserSession();
      if (userSession) {
        const accessToken = userSession.getAccessToken().getJwtToken();
        localStorage.setItem("jwt", accessToken);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/adventurers/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ id: userId }),
          }
        );
        if (response.status === 400) {
          console.log("User has created");
        } else if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  }

  async function handleSignIn() {
    try {
      await Auth.federatedSignIn();
    } catch (error) {
      console.log("Error signing in", error);
    }
  }

  async function handleSignOut() {
    try {
      await Auth.signOut();
      setUser(null);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  }

  useEffect(() => {
    setActiveVariantProps(getActiveVariantProps(variant));
  }, [variant]);

  return (
    <div className={styles.pageheaderBackground}>
      <header
        className={`${styles["pageheader-container"]} ${activeVariantProps.backgroundClass}`}
      >
        <div>
          <Link href={activeVariantProps.logoLink}>
            <img
              src={activeVariantProps.logoUrl}
              className={styles["nobos-logo"]}
              alt="Nobos logo"
            />
          </Link>
        </div>

        <nav className={styles["pageheader-nav"]}>
          {activeVariantProps.links.map(
            (link, i) =>
              !link.restricted?.(user) && (
                <Link
                  key={i}
                  href={link.href}
                  className={`${styles["nav-item"]} ${activeVariantProps.navItemStyle}`}
                  aria-label={link.ariaLabel}
                >
                  {link.displayText}
                </Link>
              )
          )}
        </nav>

        {variant === "user" && (
          <nav className={styles["site-links"]}>
            {siteLInks.map((siteLink) => (
              <a
                href={siteLink.href}
                title={siteLink.ariaLabel}
                className={styles["nav-item"]}
                key={Math.random()}
              >
                {siteLink.displayText}
              </a>
            ))}
          </nav>
        )}

        <div className="account-container">
          <Dropdown
            title={<FontAwesomeIcon icon={faUser} color="#fff" />}
            orientation="right"
          >
            <>
              {isLoggedIn && (
                <Link href="/profile" className={styles["menu-item"]}>
                  My Profile
                </Link>
              )}

              {isLoggedIn && isAdmin && (
                <Link href="/admin_dashboard" className={styles["menu-item"]}>
                  Admin
                </Link>
              )}

              {isLoggedIn && (
                <span onClick={handleSignOut} className={styles["menu-item"]}>
                  Sign out
                </span>
              )}

              {!isLoggedIn && (
                <div
                  style={{
                    textAlign: "center",
                    textWrap: "nowrap",
                    marginTop: "12px",
                  }}
                >
                  <Button label="Sign in" callback={handleSignIn} />
                </div>
              )}
            </>
          </Dropdown>
        </div>
      </header>
    </div>
  );
}
