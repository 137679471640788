import { useCallback, useEffect, useState } from "react";
import { Redirect } from "wouter";
import Button from "../components/Button/Button";
import { useForm } from "react-hook-form";
import useTestFeatureTestGps from "../utils/hooks/use_test_gps";
import { feature_testing_test_gps_bad_data } from "../dto/interfaces";

type geoDatapoint =
  | "geolocation unavailable"
  | "retrieving"
  | "error retrieving"
  | number;

export default function TestGps({
  isFeatureTester,
}: {
  isFeatureTester: boolean | null;
}) {
  const [currentLatitude, setCurrentLatitude] = useState<geoDatapoint>(
    "geolocation unavailable"
  );
  const [currentLongitude, setCurrentLongitude] = useState<geoDatapoint>(
    "geolocation unavailable"
  );
  const [mapUrl, setMapUrl] = useState<string | undefined>(undefined);
  const [initialLoad, setInitialLoad] = useState(true);
  const [useHighAccuracy, setUseHighAccuracy] = useState(false);

  const {
    addTestFeatureTestGpsSubmitPoorId,
    testFeatureTestGpsIsMutating,
    addTestFeatureTestGpsData,
    resetAddTestFeatureTestGps,
    addTestFeatureTestGpsError,
  } = useTestFeatureTestGps();

  // init form
  const { register, handleSubmit } = useForm<
    Omit<feature_testing_test_gps_bad_data, "coordinates" | "id">
  >({
    defaultValues: {
      highAccuracySolved: false,
      sameAsNative: true,
      location: "",
      details: "",
    },
  });

  const doGeo = useCallback(() => {
    if ("geolocation" in navigator) {
      setCurrentLatitude("retrieving");
      setCurrentLongitude("retrieving");
      setMapUrl(undefined);
      resetAddTestFeatureTestGps();

      /* geolocation is available */
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLatitude(position.coords.latitude);
          setCurrentLongitude(position.coords.longitude);
          setMapUrl(
            `https://maps.googleapis.com/maps/api/staticmap?markers=${position.coords.latitude},${position.coords.longitude}&size=400x400&key=AIzaSyCKQAAr5XtTgPj56D-SQXfP8m-MmlOMZk0`
          );
        },
        (error) => {
          setCurrentLatitude("error retrieving");
          setCurrentLongitude("error retrieving");
        },
        {
          enableHighAccuracy: useHighAccuracy,
          timeout: 20000,
          maximumAge: 0,
        }
      );
    } else {
      /* geolocation IS NOT available */
      setCurrentLatitude("geolocation unavailable");
      setCurrentLongitude("geolocation unavailable");
    }
  }, [
    useHighAccuracy,
    setCurrentLatitude,
    setCurrentLongitude,
    setMapUrl,
    resetAddTestFeatureTestGps,
  ]);

  const onSubmitPoorId = (
    data: Omit<feature_testing_test_gps_bad_data, "coordinates" | "id">
  ) => {
    addTestFeatureTestGpsSubmitPoorId({
      ...data,
      coordinates: [currentLatitude as number, currentLongitude as number],
    }).catch((e) => {
      console.error(e);
    });
  };

  useEffect(() => {
    doGeo();
    setInitialLoad(false);
  }, [doGeo, setInitialLoad]);

  return (
    <>
      {isFeatureTester === null ? (
        <p>Loading...</p>
      ) : isFeatureTester === false ? (
        <Redirect to="/" />
      ) : (
        <>
          <div>
            <h1>Test GPS</h1>
            <p>
              This test gets your gps coodinates from the browser's
              navigator.geolocation api, then uses the google maps api to put a
              pin on the map at those coodinates.
            </p>
            <form style={{ marginBottom: "10px" }}>
              <input
                id="useHighAccuracy"
                type="checkbox"
                checked={useHighAccuracy}
                onChange={(e) => setUseHighAccuracy(e.target.checked)}
                value={useHighAccuracy.toString()}
              />
              <label htmlFor="useHighAccuracy" style={{ marginLeft: "5px" }}>
                Use high accuracy (uses more of device battery)
              </label>
            </form>
            <h3 style={{ margin: 0 }}>Latitude: {currentLatitude}</h3>
            <h3 style={{ margin: 0 }}>Longitude: {currentLongitude}</h3>
            {mapUrl && (
              <div>
                <img src={mapUrl} alt="map" />
              </div>
            )}
            {!initialLoad && <Button callback={doGeo} label="Refresh"></Button>}
          </div>

          <hr />

          {typeof currentLatitude === "number" &&
            (!addTestFeatureTestGpsData && !addTestFeatureTestGpsError ? (
              <div style={{ marginTop: "20px" }}>
                <h1>Flag a poorly identified location</h1>
                <p>please try using High Accuracy setting also, then report</p>
                <p>
                  also please try looking at where your native map application
                  on your device (google maps, apple maps) says that you are
                </p>
                <form
                  onSubmit={handleSubmit(onSubmitPoorId)}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div>
                    <input
                      id="highAccuracySolved"
                      type="checkbox"
                      {...register("highAccuracySolved")}
                    />
                    <label
                      htmlFor="highAccuracySolved"
                      style={{ marginLeft: "5px" }}
                    >
                      High Accuracy setting solved the issue
                    </label>
                  </div>
                  <div>
                    <input
                      id="sameAsNative"
                      type="checkbox"
                      {...register("sameAsNative")}
                    />
                    <label htmlFor="sameAsNative" style={{ marginLeft: "5px" }}>
                      Location is same on native app
                    </label>
                  </div>

                  <div style={{ width: "50%", marginTop: "10px" }}>
                    <label htmlFor="location">
                      Location. Address or Description
                    </label>
                    <input
                      {...register("location")}
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div style={{ width: "50%", marginTop: "10px" }}>
                    <label htmlFor="details">
                      Details of location and/or any insight you have into why
                      gps would fail here (far from cell towers, under heavy
                      vegetation, etc)
                    </label>
                    <textarea
                      {...register("details")}
                      style={{ width: "100%" }}
                    ></textarea>
                  </div>

                  <div style={{ marginTop: "10px", width: "250px" }}>
                    <Button
                      label="Flag Poor ID"
                      disabled={testFeatureTestGpsIsMutating}
                    ></Button>
                  </div>
                </form>
              </div>
            ) : addTestFeatureTestGpsError ? (
              <p style={{ color: "red" }}>
                Error flagging poor ID: {addTestFeatureTestGpsError.message}
              </p>
            ) : (
              <p style={{ color: "green" }}>bad gps ID reported</p>
            ))}
        </>
      )}
    </>
  );
}
