import styles from './quest_block.module.scss';
import { Link } from 'wouter';
import { quest } from "../dto/interfaces";

interface QuestBlockProps {
    orientation?: string;
    width?: number;
    questData: quest;
}

export default function QuestBlock({ orientation, width, questData }: QuestBlockProps) {
    const deadline = questData.quest_deadline ? new Date(questData.quest_deadline).toLocaleDateString() : 'none';

    return (
        <div className={orientation === 'vertical' ? styles['quest_container--vertical'] : styles.quest_container} style={{ width: width ? `${width}%` : '' }}>
            <div className={styles.quest_image}>
                {questData.quest_image && <img src={questData.quest_image} alt="" />}
            </div>

            <div className={styles.quest_content}>
                <h2 className={styles.quest_title}>{questData.quest_name}</h2>
               
                <p>{questData.locations && `${questData.locations[0].locality}, ${questData.locations[0].administrative_area}`}</p>
                
                <p>Due by {deadline}</p>

                <Link href={`/quests/${questData.id}`}>Details</Link>
            </div>
        </div>
    );
}
