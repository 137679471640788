import styles from "./button.module.scss";

interface ButtonProps {
  label: string | React.ReactNode;
  callback?: () => void;
  disabled?: boolean;
}

export default function Button({ label, callback, disabled }: ButtonProps) {
  const handleClick = () => {
    callback && callback();
  };

  return (
    <button
      className={styles.button}
      onClick={() => handleClick()}
      disabled={disabled}
    >
      {label}
    </button>
  );
}
