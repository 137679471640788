import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { postFetcher, fetcher } from "../fetcher";
import { feature_testing_test_gps_bad_data } from "../../dto/interfaces";

export default function useTestFeatureTestGps() {
  const { data: getTestFeatureTestGpsData, error: getTestFeatureTestGpsError } =
    useSWR(`${process.env.REACT_APP_API_URL}/test_feature_test_gps/`, fetcher);

  const {
    trigger: addTestFeatureTestGpsSubmitPoorId,
    data: addTestFeatureTestGpsData,
    isMutating: testFeatureTestGpsIsMutating,
    error: addTestFeatureTestGpsError,
    reset: resetAddTestFeatureTestGps,
  } = useSWRMutation(
    `${process.env.REACT_APP_API_URL}/test_feature_test_gps/`,
    async (
      key: string,
      arg: { arg: Omit<feature_testing_test_gps_bad_data, "id"> }
    ) => {
      return postFetcher(key, arg.arg);
    },
    {
      revalidate: false,
    }
  );

  return {
    addTestFeatureTestGpsSubmitPoorId,
    addTestFeatureTestGpsData,
    testFeatureTestGpsIsMutating,
    addTestFeatureTestGpsError,
    resetAddTestFeatureTestGps,
    getTestFeatureTestGpsError,
    getTestFeatureTestGpsData,
  };
}
