interface GetGeoCoordinatesProps {
  useHighAccuracy?: boolean;
  timeout?: number;
  maximumAge?: number;
}

export const getGeoCoordinates = ({
  useHighAccuracy = false,
  timeout = 30000,
  maximumAge = 0,
}: GetGeoCoordinatesProps = {}): Promise<{
  latitude: number;
  longitude: number;
}> => {
  return new Promise((resolve, reject) => {
    if (!("geolocation" in navigator)) {
      return "Geolocation is not available";
    }
    /* geolocation is available */
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        reject(`Geolocation error: ${error.message}`);
      },
      {
        enableHighAccuracy: useHighAccuracy,
        timeout,
        maximumAge,
      }
    );
  });
};
